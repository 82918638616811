export function Cantoneiras() {
    return (

        <section className="blog-page sec-pad">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="single-blog-page-content">
                            <h3>Cantoneiras</h3>
                            <div className="meta-info">
                                <a href="#"><i className="fas fa-check" />
                                    As cantoneiras em aço inox são muito utilizadas nos setores moveleiro, transporte, construção civil, entre outros.
                                </a>
                                <br />
                                <a href="#"><i className="fas fa-check" />
                                    <b>Ligas disponíveis: </b>304, 304L, 310, 316, 316L, 410, 420 e Série 200.
                                </a>
                            </div>{/* /.meta-info */}
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className="text-center">Designação</th>
                                        <th scope="col" className="text-center">Peso</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    <tr>
                                        <td>3/4” x 3/4”1/8”</td><td>0.88</td>
                                    </tr>
                                    <tr>
                                        <td>7/8” x 7/8”1/8”</td><td>1.04</td>
                                    </tr>
                                    <tr>
                                        <td>1” x 1”1/8”</td><td>1.20</td>
                                    </tr>
                                    <tr>
                                        <td>1.1/8” x 1.1/8”1/8”</td><td>1.36</td>
                                    </tr>
                                    <tr>
                                        <td>1.1/4” x 1.1/4”1/8”</td><td>1.52</td>
                                    </tr>
                                    <tr>
                                        <td>1.1/2” x 1.1/2”1/8”</td><td>1.85</td>
                                    </tr>
                                    <tr>
                                        <td>2” x 2”1/8”</td><td>2.49</td>
                                    </tr>
                                    <tr>
                                        <td>3/4” x 3/4”3/16”</td><td>1.26</td>
                                    </tr>
                                    <tr>
                                        <td>7/8” x 7/8”3/16”</td><td>1.51</td>
                                    </tr>
                                    <tr>
                                        <td>1” x 1”3/16”</td><td>1.75</td>
                                    </tr>
                                    <tr>
                                        <td>1.1/4” x 1.1/4”3/16”</td><td>2.23</td>
                                    </tr>
                                    <tr>
                                        <td>1.1/2” x 1.1/2”3/16”</td><td>2.72</td>
                                    </tr>
                                    <tr>
                                        <td>2” x 2”3/16”</td><td>3.68</td>
                                    </tr>
                                    <tr>
                                        <td>2.1/2” x 2.1/2”3/16”</td><td>4.65</td>
                                    </tr>
                                    <tr>
                                        <td>3” x 3’3/16”</td><td>5.62</td>
                                    </tr>
                                    <tr>
                                        <td>1”  x 1”1/4”</td><td>2.25</td>
                                    </tr>
                                    <tr>
                                        <td>1.1/4” x 1.1/4”1/4”</td><td>2.90</td>
                                    </tr>
                                    <tr>
                                        <td>1.1/2” x 1.1/2”1/4”</td><td>3.54</td>
                                    </tr>
                                    <tr>
                                        <td>2” x 2”1/4”</td><td>4.83</td>
                                    </tr>
                                    <tr>
                                        <td>2.1/2” x 2.1/2”1/4”</td><td>6.12</td>
                                    </tr>
                                    <tr>
                                        <td>3” x 3”1/4’</td><td>7.41</td>
                                    </tr>
                                    <tr>
                                        <td>4’ x 4”1/4”</td><td>9.99</td>
                                    </tr>
                                    <tr>
                                        <td>1.1/2” x 1.1/2”5/16”</td><td>4.33</td>
                                    </tr>
                                    <tr>
                                        <td>2” x 2”5/16”</td><td>5.942</td>
                                    </tr>
                                    <tr>
                                        <td>2.1/2” x 2.1/2”5/16”</td><td>7.553</td>
                                    </tr>
                                    <tr>
                                        <td>3” x 3”5/16”</td><td>9.16</td>
                                    </tr>
                                    <tr>
                                        <td>1.1/2” x 1.1/2”3/8”</td><td>5.08</td>
                                    </tr>
                                    <tr>
                                        <td>2” x 2”3/8”</td><td>7.01</td>
                                    </tr>
                                    <tr>
                                        <td>2.1/2” x 2.1/2”3/8”</td><td>8.95</td>
                                    </tr>
                                    <tr>
                                        <td>3” x 3”3/8”</td><td>10.89</td>
                                    </tr>
                                    <tr>
                                        <td>4” x 4”3/8”</td><td>14.76</td>
                                    </tr>
                                    <tr>
                                        <td>2.1/2” x 2.1/2”1/2”</td><td>11.61</td>
                                    </tr>
                                    <tr>
                                        <td>3” x 3”1/2”</td><td>14.19</td>
                                    </tr>
                                    <tr>
                                        <td>4” x 4”1/2”</td><td>19.35</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>{/* /.single-blog-page-content */}
                    </div>{/* /.col-md-8 */}
                </div>{/* /.row */}
            </div>{/* /.container */}
        </section >
    );
}