import { Link } from "react-router-dom";

export function MoreInfo() {
    return (
        <section className="cta-style-one">
            <div className="inner">
                <div className="container">
                    <div className="title pull-left">
                        <h3>Faça sua cotação com a gente</h3>
                    </div>{/* /.title pull-left */}
                    <div className="btn-box pull-right">
                        <Link to="/contact" className="cta-btn">Entrar em Contato</Link>
                    </div>{/* /.btn-box */}
                </div>{/* /.container */}
            </div>{/* /.inner */}
        </section>
    );
}