import { Link } from "react-router-dom";

import headerLogo from "../assets/logo/header-logo.webp";

export function Header() {
    return (
        <header className="header header-home-three">
            <div className="header-top">
                <div className="container">
                    <div className="pull-left left-contact-info">
                        <p>
                            <a href="mailto:fortinox.bahia@hotmail.com" style={{ color: "#fff" }}>
                                <i className="fas fa-envelope-open" /> fortinox.bahia@hotmail.com
                            </a>
                        </p>
                        <p>
                            <a href="tel:7141410554" style={{ color: "#fff" }}>
                                <i className="zxp-icon-old-telephone-ringing" />(71) 4141-0554
                            </a>
                        </p>
                    </div>{/* /.pull-left */}
                    <div className="pull-right right-contact-info">
                        <p><i className="fas fa-clock" />Seg - Sex : 8:00 às 17:00 | Sab : 	08:00 às 12:00</p>
                    </div>{/* /.pull-right */}
                </div>{/* /.container */}
            </div>{/* /.header-top */}
            <nav className="navbar navbar-default header-navigation stricky">
                <div className="container clearfix">
                    {/* Brand and toggle get grouped for better mobile display */}
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed zxp-icon-menu" data-toggle="collapse" data-target=".main-navigation" aria-expanded="false"> </button>
                        <Link className="navbar-brand" to="/">
                            <img style={{ marginLeft: '15px' }} src={headerLogo} alt="Header" />
                        </Link>
                    </div>
                    {/* Collect the nav links, forms, and other content for toggling */}
                    <div className="collapse navbar-collapse main-navigation mainmenu " id="main-nav-bar">
                        <ul className="nav navbar-nav navigation-box">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li> <Link to="/about-us">Sobre nós</Link> </li>
                            <li>
                                <a href="#">Produtos</a>
                                <ul className="sub-menu">
                                    <li> <Link to="/product/barras-redondas">Barras Redondas</Link></li>
                                    <li> <Link to="/product/bobinas">Bobinas</Link></li>
                                    <li> <Link to="/product/cantoneiras">Cantoneiras</Link></li>
                                    <li> <Link to="/product/chapas">Chapas</Link></li>
                                    <li> <Link to="/product/tubos-quadrados">Tubos Quadrados</Link></li>
                                    <li> <Link to="/product/tubos-retangulares">Tubos Retangulares</Link></li>
                                    <li> <Link to="/product/tubos-schedule">Tubos Schedule</Link></li>
                                    <li> <Link to="/product/tubos-od-tubing">Tubos OD / Tubing</Link></li>
                                </ul>{/* /.sub-menu */}
                            </li>
                            <li> <Link to="/contact">Contato</Link> </li>
                        </ul>
                    </div>{/* /.navbar-collapse */}
                    <div className="right-side-box">
                        <Link to="/contact-price" className="rqa-btn">Solicitar Cotação</Link>
                    </div>{/* /.right-side-box */}
                </div>{/* /.container */}
            </nav>
        </header>

    );
}