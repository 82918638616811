type BreadcrumbContextType = {
    namePage: string;
    imagePage: string;
}

export function Breadcrumbs(props: BreadcrumbContextType) {
    return (
        <section className="inner-banner" style={{

            background: `#F9B522 url(${props.imagePage}) center center / cover no-repeat`,
            paddingTop: "240px",
            paddingBottom: "120px"
        }}>
            <div className="container text-center">
                <h3>{props.namePage}</h3>
                <div className="breadcumb">
                    <a href="#">Home</a>
                    <span className="sep">-</span>
                    <span className="page-name">{props.namePage}</span>
                </div>{/* /.breadcumb */}
            </div>{/* /.container */}
        </section>

    );
}