import { Link } from 'react-router-dom';
import barrasredondas from '../assets/product/barrasredondas.webp';
import bobinas from '../assets/product/bobinas.webp';
import cantoneiras from '../assets/product/cantoneiras.webp';
import chapas from '../assets/product/chapas.webp';
import tubosod from '../assets/product/tubosod.webp';
import tubosquadrados from '../assets/product/tubosquadrados.webp';
import tubosretangulares from '../assets/product/tubosretangulares.webp';
import tubosschedule from '../assets/product/tubosschedule.webp';

type ProductsContextType = {
    namePage: string | undefined;
}

export function Products(props: ProductsContextType) {
    return (
        <section className="service-style-one sec-pad home-page-three" id="products">
            <div className="container">
                <div className="sec-title">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3>{props.namePage === "home" ? "Consulte nossos materiais" : "Consulte outros materiais"}</h3>
                        </div>
                    </div>{/* /.row */}
                </div>{/* /.sec-title */}
                <div className="row">
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="single-service-style-one">
                            <div className="img-box">
                                <img src={barrasredondas} alt="Barras Redondas" />
                                <Link to="/product/barras-redondas" className="read-more fas fa-link" />
                            </div>{/* /.img-box */}
                            <div className="content-box">
                                <div className="icon-box">
                                    <i className="zxp-icon-plan" />
                                </div>{/* /.icon-box */}
                                <div className="text-box">
                                    <Link to="/product/barras-redondas"><h3>Barras Redondas</h3></Link>
                                </div>{/* /.text-box */}
                            </div>{/* /.content-box */}
                        </div>{/* /.single-service-style-one */}
                    </div>{/* /.col-md-3 */}
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="single-service-style-one">
                            <div className="img-box">
                                <img src={bobinas} alt="Bobinas" />
                                <Link to="/product/bobinas" className="read-more fas fa-link" />
                            </div>{/* /.img-box */}
                            <div className="content-box">
                                <div className="icon-box">
                                    <i className="zxp-icon-plan" />
                                </div>{/* /.icon-box */}
                                <div className="text-box">
                                    <Link to="/product/bobinas"><h3>Bobinas</h3></Link>
                                </div>{/* /.text-box */}
                            </div>{/* /.content-box */}
                        </div>{/* /.single-service-style-one */}
                    </div>{/* /.col-md-3 */}
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="single-service-style-one">
                            <div className="img-box">
                                <img src={cantoneiras} alt="Cantoneiras" />
                                <Link to="/product/cantoneiras" className="read-more fas fa-link" />
                            </div>{/* /.img-box */}
                            <div className="content-box">
                                <div className="icon-box">
                                    <i className="zxp-icon-plan" />
                                </div>{/* /.icon-box */}
                                <div className="text-box">
                                    <Link to="/product/cantoneiras"><h3>Cantoneiras</h3></Link>
                                </div>{/* /.text-box */}
                            </div>{/* /.content-box */}
                        </div>{/* /.single-service-style-one */}
                    </div>{/* /.col-md-3 */}
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="single-service-style-one">
                            <div className="img-box">
                                <img src={chapas} alt="Chapas" />
                                <Link to="/product/chapas" className="read-more fas fa-link" />
                            </div>{/* /.img-box */}
                            <div className="content-box">
                                <div className="icon-box">
                                    <i className="zxp-icon-plan" />
                                </div>{/* /.icon-box */}
                                <div className="text-box">
                                    <Link to="/product/chapas"><h3>Chapas</h3></Link>
                                </div>{/* /.text-box */}
                            </div>{/* /.content-box */}
                        </div>{/* /.single-service-style-one */}
                    </div>{/* /.col-md-3 */}
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="single-service-style-one">
                            <div className="img-box">
                                <img src={tubosod} alt="Tubos OD" />
                                <Link to="/product/tubos-od-tubing" className="read-more fas fa-link" />
                            </div>{/* /.img-box */}
                            <div className="content-box">
                                <div className="icon-box">
                                    <i className="zxp-icon-plan" />
                                </div>{/* /.icon-box */}
                                <div className="text-box">
                                    <Link to="/product/tubos-od-tubing"><h3>Tubos OD / Tubing</h3></Link>
                                </div>{/* /.text-box */}
                            </div>{/* /.content-box */}
                        </div>{/* /.single-service-style-one */}
                    </div>{/* /.col-md-3 */}
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="single-service-style-one">
                            <div className="img-box">
                                <img src={tubosquadrados} alt="Tubos Quadrados" />
                                <Link to="/product/tubos-quadrados" className="read-more fas fa-link" />
                            </div>{/* /.img-box */}
                            <div className="content-box">
                                <div className="icon-box">
                                    <i className="zxp-icon-plan" />
                                </div>{/* /.icon-box */}
                                <div className="text-box">
                                    <Link to="/product/tubos-quadrados"><h3>Tubos Quadrados</h3></Link>
                                </div>{/* /.text-box */}
                            </div>{/* /.content-box */}
                        </div>{/* /.single-service-style-one */}
                    </div>{/* /.col-md-3 */}
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="single-service-style-one">
                            <div className="img-box">
                                <img src={tubosretangulares} alt="Tubos Retangulares" />
                                <Link to="/product/tubos-retangulares" className="read-more fas fa-link" />
                            </div>{/* /.img-box */}
                            <div className="content-box">
                                <div className="icon-box">
                                    <i className="zxp-icon-plan" />
                                </div>{/* /.icon-box */}
                                <div className="text-box">
                                    <Link to="/product/tubos-retangulares"><h3>Tubos Retangulares</h3></Link>
                                </div>{/* /.text-box */}
                            </div>{/* /.content-box */}
                        </div>{/* /.single-service-style-one */}
                    </div>{/* /.col-md-3 */}
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="single-service-style-one">
                            <div className="img-box">
                                <img src={tubosschedule} alt="Tubos Schedule" />
                                <Link to="/product/tubos-schedule" className="read-more fas fa-link" />
                            </div>{/* /.img-box */}
                            <div className="content-box">
                                <div className="icon-box">
                                    <i className="zxp-icon-plan" />
                                </div>{/* /.icon-box */}
                                <div className="text-box">
                                    <Link to="/product/tubos-schedule"><h3>Tubos Schedule</h3></Link>
                                </div>{/* /.text-box */}
                            </div>{/* /.content-box */}
                        </div>{/* /.single-service-style-one */}
                    </div>{/* /.col-md-3 */}
                </div>{/* /.row */}
            </div>{/* /.container */}
        </section>


    );
}