export function Chapas() {
    return (

        <section className="blog-page sec-pad">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="single-blog-page-content">
                            <h3>Chapax de Aço Inox</h3>
                            <div className="meta-info">
                                <a href="#"><i className="fas fa-check" />
                                    Na FortInox você encontra uma ampla linha de chapas de aço inox: lisas ou com PVC, polidas, escovadas e perfuradas.
                                </a>
                                <br />
                                <a href="#"><i className="fas fa-check" />
                                    Nossa linha de chapas em aço inox tem diversas opções de espessura e é fabricada de acordo com a norma ASTM A:240.
                                </a>
                                <br />
                                <a href="#"><i className="fas fa-check" />
                                    Além da chapa em aço inox 304, você encontra na FortInox chapas nas ligas 304L, 316, 316L, 430, série 200, 300 e 410S.
                                </a>
                            </div>{/* /.meta-info */}
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            2X1.220
                                        </th>
                                        <th scope="col">
                                            2X1.250
                                        </th>
                                        <th scope="col">
                                            3X1000
                                        </th>
                                        <th scope="col">
                                            3X1.220
                                        </th>
                                        <th scope="col">
                                            3X1.250
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>

                                        <th scope="col">mm</th>
                                        <th scope="col">Polegadas</th>
                                        <th scope="col">No U.S.G</th>
                                        <th scope="col">2.44</th>
                                        <th scope="col">2.5</th>
                                        <th scope="col">3</th>
                                        <th scope="col">3.66</th>
                                        <th scope="col">3.75</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    <tr>
                                        <td>0.3</td>
                                        <td>-</td>
                                        <td>30</td>
                                        <td>5.856</td>
                                        <td>6</td>
                                        <td>7.2</td>
                                        <td>8.784</td>
                                        <td>9</td>
                                    </tr>
                                    <tr>
                                        <td>0.4</td>
                                        <td>1/64"</td>
                                        <td>28</td>
                                        <td>7.808</td>
                                        <td>8</td>
                                        <td>9.6</td>
                                        <td>11.712</td>
                                        <td>12</td>
                                    </tr>
                                    <tr>
                                        <td>0.5</td>
                                        <td>-</td>
                                        <td>26</td>
                                        <td>9.76</td>
                                        <td>10</td>
                                        <td>12</td>
                                        <td>14.64</td>
                                        <td>15</td>
                                    </tr>
                                    <tr>
                                        <td>0.6</td>
                                        <td>-</td>
                                        <td>24</td>
                                        <td>11.712</td>
                                        <td>12</td>
                                        <td>14.4</td>
                                        <td>17.568</td>
                                        <td>18</td>
                                    </tr>
                                    <tr>
                                        <td>0.8</td>
                                        <td>1/32"</td>
                                        <td>20</td>
                                        <td>15.616</td>
                                        <td>16</td>
                                        <td>19.2</td>
                                        <td>23.424</td>
                                        <td>24</td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>-</td>
                                        <td>18</td>
                                        <td>19.52</td>
                                        <td>20</td>
                                        <td>24</td>
                                        <td>29.28</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <td>1.2</td>
                                        <td>-</td>
                                        <td>16</td>
                                        <td>23.424</td>
                                        <td>24</td>
                                        <td>28.8</td>
                                        <td>35.136</td>
                                        <td>36</td>
                                    </tr>
                                    <tr>
                                        <td>1.5</td>
                                        <td>1/16"</td>
                                        <td>14</td>
                                        <td>29.28</td>
                                        <td>30</td>
                                        <td>36</td>
                                        <td>43.92</td>
                                        <td>45</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>5/64"</td>
                                        <td>13</td>
                                        <td>39.04</td>
                                        <td>40</td>
                                        <td>48</td>
                                        <td>58.56</td>
                                        <td>60</td>
                                    </tr>
                                    <tr>
                                        <td>2,5</td>
                                        <td>-</td>
                                        <td>11</td>
                                        <td>48.8</td>
                                        <td>50</td>
                                        <td>60</td>
                                        <td>73.2</td>
                                        <td>75</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>1/8"</td>
                                        <td>10</td>
                                        <td>58.56</td>
                                        <td>60</td>
                                        <td>72</td>
                                        <td>87.84</td>
                                        <td>90</td>
                                    </tr>
                                    <tr>
                                        <td>3,5</td>
                                        <td>9/64"</td>
                                        <td>9</td>
                                        <td>68.32</td>
                                        <td>70</td>
                                        <td>84</td>
                                        <td>102.48</td>
                                        <td>105</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>5/32"</td>
                                        <td>8</td>
                                        <td>78.08</td>
                                        <td>80</td>
                                        <td>96</td>
                                        <td>117.12</td>
                                        <td>120</td>
                                    </tr>
                                    <tr>
                                        <td>4.5</td>
                                        <td>11/64"</td>
                                        <td>7</td>
                                        <td>87.84</td>
                                        <td>90</td>
                                        <td>108</td>
                                        <td>131.76</td>
                                        <td>135</td>
                                    </tr>
                                    <tr>
                                        <td>4.76</td>
                                        <td>3/16"</td>
                                        <td>5</td>
                                        <td>92.9152</td>
                                        <td>95.2</td>
                                        <td>114.24</td>
                                        <td>139.3728</td>
                                        <td>142.8</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>-</td>
                                        <td>4</td>
                                        <td>97.6</td>
                                        <td>100</td>
                                        <td>120</td>
                                        <td>146.4</td>
                                        <td>150</td>
                                    </tr>
                                    <tr>
                                        <td>5.5</td>
                                        <td>7/32"</td>
                                        <td>3</td>
                                        <td>107.36</td>
                                        <td>110</td>
                                        <td>132</td>
                                        <td>161.04</td>
                                        <td>165</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>-</td>
                                        <td>0</td>
                                        <td>117.12</td>
                                        <td>120</td>
                                        <td>144</td>
                                        <td>175.68</td>
                                        <td>180</td>
                                    </tr>
                                    <tr>
                                        <td>6.35</td>
                                        <td>1/4"</td>
                                        <td>-</td>
                                        <td>123.952</td>
                                        <td>127</td>
                                        <td>152.4</td>
                                        <td>185.928</td>
                                        <td>190.5</td>
                                    </tr>
                                    <tr>
                                        <td>7.93</td>
                                        <td>5/16"</td>
                                        <td>154.7936</td>
                                        <td>158.6</td>
                                        <td>190.32</td>
                                        <td>232.1904</td>
                                        <td>237.9</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>156.16</td>
                                        <td>160</td>
                                        <td>192</td>
                                        <td>234.24</td>
                                        <td>240</td>
                                    </tr>
                                    <tr>
                                        <td>9.53</td>
                                        <td>3/8"</td>
                                        <td>-</td>
                                        <td>186.0256</td>
                                        <td>190.6</td>
                                        <td>228.72</td>
                                        <td>279.0384</td>
                                        <td>285.9</td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>195.2</td>
                                        <td>200</td>
                                        <td>240</td>
                                        <td>292.8</td>
                                        <td>300</td>
                                    </tr>
                                    <tr>
                                        <td>12.7</td>
                                        <td>1/2"</td>
                                        <td>-</td>
                                        <td>247.904</td>
                                        <td>254</td>
                                        <td>304.8</td>
                                        <td>371.856</td>
                                        <td>381</td>
                                    </tr>
                                    <tr>
                                        <td>15.87</td>
                                        <td>5/8"</td>
                                        <td>-</td>
                                        <td>309.7824</td>
                                        <td>317.4</td>
                                        <td>380.88</td>
                                        <td>464.6736</td>
                                        <td>476.1</td>
                                    </tr>
                                    <tr>
                                        <td>19.05</td>
                                        <td>3/4"</td>
                                        <td>-</td>
                                        <td>371.856	</td>
                                        <td>381</td>
                                        <td>457.2</td>
                                        <td>557.784</td>
                                        <td>571.5</td>
                                    </tr>
                                    <tr>
                                        <td>22.22</td>
                                        <td>7/8"</td>
                                        <td>-</td>
                                        <td>433.7344</td>
                                        <td>444.4</td>
                                        <td>533.28</td>
                                        <td>650.6016</td>
                                        <td>666.6</td>
                                    </tr>
                                    <tr>
                                        <td>25.4</td>
                                        <td>1"</td>
                                        <td>-</td>
                                        <td>495.808</td>
                                        <td>508</td>
                                        <td>609.6</td>
                                        <td>743.712</td>
                                        <td>762</td>
                                    </tr>
                                    <tr>
                                        <td>31.75</td>
                                        <td>1.14"</td>
                                        <td>-</td>
                                        <td>619.76</td>
                                        <td>635</td>
                                        <td>762</td>
                                        <td>929.64</td>
                                        <td>952.5</td>
                                    </tr>
                                    <tr>
                                        <td>38.1</td>
                                        <td>1.1/2"</td>
                                        <td>-</td>
                                        <td>743.712</td>
                                        <td>762</td>
                                        <td>914.4</td>
                                        <td>1115.568</td>
                                        <td>1143</td>
                                    </tr>
                                    <tr>
                                        <td>44.45</td>
                                        <td>1.3/4"</td>
                                        <td>-</td>
                                        <td>867.664</td>
                                        <td>889</td>
                                        <td>1066.8</td>
                                        <td>1301.496</td>
                                        <td>1333.5</td>
                                    </tr>
                                    <tr>
                                        <td>50.8</td>
                                        <td>2"</td>
                                        <td>-</td>
                                        <td>991.616</td>
                                        <td>1016</td>
                                        <td>1219.2</td>
                                        <td>1487.424</td>
                                        <td>1524</td>
                                    </tr>
                                    <tr>
                                        <td>65.09</td>
                                        <td>2.9/16"</td>
                                        <td>-</td>
                                        <td>1270.5568</td>
                                        <td>1301.8</td>
                                        <td>1562.16</td>
                                        <td>1905.8352</td>
                                        <td>1952.7</td>
                                    </tr>
                                    <tr>
                                        <td>69.85</td>
                                        <td>2.3/4"</td>
                                        <td>-</td>
                                        <td>1363.472</td>
                                        <td>1397</td>
                                        <td>1676.4</td>
                                        <td>2045.208</td>
                                        <td>2095.5</td>
                                    </tr>
                                    <tr>
                                        <td>88.9</td>
                                        <td>3.1/2"</td>
                                        <td>-</td>
                                        <td>1735.328</td>
                                        <td>1778</td>
                                        <td>2133.6</td>
                                        <td>2602.992</td>
                                        <td>2667</td>
                                    </tr>
                                    <tr>
                                        <td>101.6</td>
                                        <td>4"</td>
                                        <td>-</td>
                                        <td>1983.232</td>
                                        <td>2032</td>
                                        <td>2438.4</td>
                                        <td>2974.848</td>
                                        <td>3048</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>{/* /.single-blog-page-content */}
                    </div>{/* /.col-md-8 */}
                </div>{/* /.row */}
            </div>{/* /.container */}
        </section>

    );
}