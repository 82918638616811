import emailjs from '@emailjs/browser';
import { FormEvent, useRef, useState } from 'react';
import ReactGA from 'react-ga';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function Contact() {

    const MySwal = withReactContent(Swal);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [message, setMessage] = useState("");
    const [assunto, setAssunto] = useState("");

    const form = useRef<any>();

    const sendEmailForm = (e: FormEvent) => {
        e.preventDefault();

        ReactGA.event({
            category: 'event',
            action: 'conversion',
            label: '/contact',
        })

        emailjs.sendForm('service_ou8h4xs', 'template_vwm8qhg', form.current, '62uANeVoJgE5dnHM3')
            .then(() => {

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Enviado com sucesso!'
                })

                setName("");
                setEmail("");
                setTelefone("");
                setMessage("");
                setAssunto("");

            }).catch((err) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'error',
                    title: 'Tente novamente!'
                })
            });
    };

    return (
        <div>
            <section className="contact-page-content sec-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <form ref={form} onSubmit={sendEmailForm} className="contact-form row">
                                <div className="col-md-6">
                                    <p>Nome completo</p>
                                    <input type="text"
                                        name="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-6">
                                    <p>Email</p>
                                    <input type="email"
                                        name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-6">
                                    <p>Número de Telefone</p>
                                    <input type="text"
                                        name="telefone" value={telefone} onChange={(e) => { setTelefone(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-6">
                                    <p>Assunto</p>
                                    <input type="text" name="assunto"
                                        value={assunto} onChange={(e) => { setAssunto(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-12">
                                    <p>Escreva Message</p>
                                    <textarea name="message" rows={5} defaultValue={""}
                                        value={message} onChange={(e) => { setMessage(e.target.value) }} />
                                    <button type="submit">Enviar</button>
                                </div>{/* /.col-md-6 */}
                            </form>{/* /.contact-form */}
                            <div className="result" />{/* /.result */}
                        </div>{/* /.col-md-8 */}
                        <div className="col-md-4">
                            <div className="contact-info">
                                <h3>Deixe-nos uma mensagem</h3>
                                <p>Para qualquer tipo de consulta, contacte-nos através do detalhes abaixo.</p>
                                <div className="single-contact-info">
                                    <i className="zxp-icon-old-telephone-ringing" />
                                    <p>
                                        <a href="tel:7141410554">
                                            (71) 4141-0554
                                        </a>
                                    </p>
                                </div>{/* /.single-contact-info */}
                                <div className="single-contact-info">
                                    <i className="fa fa-whatsapp" />
                                    <p>
                                        <a href="https://api.whatsapp.com/send?phone=557141410554&text=Olá, seja bem-vindo(a) a Fortinox! Ficamos felizes em atendê-lo(a). Para iniciarmos nosso atendimento, nos informe seu nome e onde nos conheceu, por gentileza?">
                                            (71) 9 8772-4706
                                        </a>
                                    </p>
                                </div>{/* /.single-contact-info */}
                                <div className="single-contact-info">
                                    <i className="fa fa-whatsapp" />
                                    <p>
                                        <a href="https://api.whatsapp.com/send?phone=557141410554&text=Olá, seja bem-vindo(a) a Fortinox! Ficamos felizes em atendê-lo(a). Para iniciarmos nosso atendimento, nos informe seu nome e onde nos conheceu, por gentileza?">
                                            (71) 9 8880-7044
                                        </a>
                                    </p>
                                </div>{/* /.single-contact-info */}
                                <div className="single-contact-info">
                                    <i className="fas fa-envelope-open" />
                                    <p>
                                        <a href="mailto:fortinox.bahia@hotmail.com">
                                            fortinox.bahia@hotmail.com
                                        </a>
                                    </p>
                                </div>{/* /.single-contact-info */}
                            </div>{/* /.contact-info */}
                        </div>{/* /.col-md-4 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </section>{/* /.contact-page-content */}
            <div className="google-map" id="contact-google-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.219655425859!2d-38.478893285178195!3d-12.957791690864868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7161acf0742f877%3A0x631579573ebaefe9!2sFORTinox!5e0!3m2!1spt-PT!2spt!4v1645314937994!5m2!1spt-PT!2spt" width="100%" height="100%" style={{ border: 0 }} loading="lazy"></iframe>
            </div>
        </div>

    );
}
