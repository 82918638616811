import aboutus from '../assets/about/aboutus.webp';

export function AboutCompany() {
    return (
        <section className="about-us-style-one sec-pad">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="about-content">
                            <span>Sobre Nós</span>
                            <h3>Experiência e <br /> Excelência</h3>
                            <h4>A FortInox é sinônimos de Aço Inoxidável na Bahia.</h4>
                            <p>
                                A FortInox é uma empresa especializada no comércio de aço inoxidável,
                                acessórios e componentes industriais, importamos, tratamos e
                                distribuímos aço inox há mais de 5 anos,
                                nas mais variadas formas e para múltiplas e distintas áreas de
                                negócio.

                                Sempre que o aço inoxidável é a escolha para alguma aplicação, a FortInox,
                                dotada de vários produtos e de uma equipa experiente, oferece soluções,
                                num compromisso sério entre qualidade, prazo e preço.

                            </p>
                            {/* <a href="#" className="about-btn">Serviços</a> */}
                        </div>{/* /.about-content */}
                    </div>{/* /.col-md-6 */}
                    <div className="col-md-6 text-right">
                        <img src={aboutus} alt="About Us" />
                    </div>{/* /.col-md-6 */}
                </div>{/* /.row */}
            </div>{/* /.container */}
        </section>

    );
}