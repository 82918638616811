import slide2 from "../assets/slide/background.webp";
import footerLogo from "../assets/logo/footer-logo-1-1.webp";
import { Link } from "react-router-dom";

export function Footer() {

    let newDate = new Date();
    let year = newDate.getFullYear();

    return (
        <div>
            <footer className="site-footer"
                style={{
                    background: `#242C33 url(${slide2}) top center / cover no-repeat fixed`
                }}
            >
                <div className="footer-top">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="footer-widget about-widget">
                                    <Link to="/"><img src={footerLogo} alt="Home" /></Link>
                                    <p>
                                        Sempre que o aço inoxidável é a escolha para alguma aplicação, a FortInox, dotada de vários produtos e de uma equipa experiente, oferece soluções, num compromisso sério entre qualidade, prazo e preço.
                                    </p>
                                    <div className="social">
                                        <a href="https://www.facebook.com/fortinox.bahia.7" target="_blank" className="fab fa-facebook-f" />
                                        <a href="https://www.instagram.com/fortinox.bahia/" target="_blank" className="fab fa-instagram" />
                                        <a href="https://goo.gl/maps/Xn8UKKZCHcBYGYZB9" target="_blank" className="fas fa-map-marker-alt" />
                                    </div>{/* /.social */}
                                </div>{/* /.footer-widget about-widget */}
                            </div>{/* /.col-md-3 */}
                            <div className="col-md-2 col-sm-6 col-xs-12">
                                <div className="footer-widget links-widget">
                                    <div className="title">
                                        <h3>Links Rápidos</h3>
                                    </div>{/* /.title */}
                                    <ul className="links-list">
                                        <li><Link to="/"><i className="fas fa-angle-right" />Home</Link></li>
                                        <li><Link to="/about-us"><i className="fas fa-angle-right" />Sobre Nós</Link></li>
                                        <li><Link to="/contact"><i className="fas fa-angle-right" />Contato</Link></li>
                                    </ul>{/* /.links-list */}
                                </div>{/* /.footer-widget links-widget */}
                            </div>{/* /.col-md-2 */}
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="footer-widget twitter-widget">
                                    <div className="title">
                                        <h3>Produtos</h3>
                                    </div>{/* /.title */}
                                    <ul className="links-list">
                                        <li><Link to="/product/barras-redondas"><i className="fas fa-angle-right" />Barras Redondas</Link></li>
                                        <li><Link to="/product/bobinas"><i className="fas fa-angle-right" />Bobinas</Link></li>
                                        <li><Link to="/product/cantoneiras"><i className="fas fa-angle-right" />Cantoneiras</Link></li>
                                        <li><Link to="/product/chapas"><i className="fas fa-angle-right" />Chapas</Link></li>
                                    </ul>{/* /.links-list */}
                                </div>{/* /.footer-widget twitter-widget */}
                            </div>{/* /.col-md-4 */}
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="footer-widget recent-post-widget">
                                    <div className="title">
                                        <h3>Produtos</h3>
                                    </div>{/* /.title */}
                                    <ul className="links-list">
                                        <li><Link to="/product/tubos-quadrados"><i className="fas fa-angle-right" />Tubos Quadrados</Link></li>
                                        <li><Link to="/product/tubos-retangulares"><i className="fas fa-angle-right" />Tubos Retangulares</Link></li>
                                        <li><Link to="/product/tubos-schedule"><i className="fas fa-angle-right" />Tubos Schedule</Link></li>
                                        <li><Link to="/product/tubos-od-tubing"><i className="fas fa-angle-right" />Tubos OD / Tubing</Link></li>
                                    </ul>{/* /.links-list */}
                                </div>{/* /.footer-widget twitter-widget */}
                            </div>{/* /.col-md-3 */}
                        </div>{/* /.row */}
                    </div>{/* /.container */}
                </div>{/* /.footer-top */}
            </footer>{/* /.site-footer */}
            <div className="footer-bottom">
                <div className="container">
                    <div className="copy-text pull-left">
                        <p>{year} © Developer By
                            <a href="https://www.instagram.com/trevodigitalpt/" target="_blank"> Trevo Digital</a>
                        </p>
                    </div>{/* /.copy-text */}
                    <div className="right-link pull-right">
                        <Link to="/privacy-policy">Privacy Policy &amp; Terms of Use</Link>
                    </div>{/* /.right-link */}
                </div>{/* /.container */}
            </div>{/* /.footer-bottom */}
        </div >
    );
}



