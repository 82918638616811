import emailjs from '@emailjs/browser';
import { FormEvent, useRef, useState } from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function RequestQuote() {

    const MySwal = withReactContent(Swal);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [message, setMessage] = useState("");
    const [assunto, setAssunto] = useState("");

    const form = useRef<any>();

    const sendEmailForm = (e: FormEvent) => {
        e.preventDefault();

        emailjs.sendForm('service_ou8h4xs', 'template_vwm8qhg', form.current, '62uANeVoJgE5dnHM3')
            .then(() => {

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Enviado com sucesso!'
                })

                setName("");
                setEmail("");
                setTelefone("");
                setMessage("");
                setAssunto("");

            }).catch((err) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'error',
                    title: 'Tente novamente!'
                })
            });
    };

    return (
        <section className="request-a-qoute">
            <div className="inner-box sec-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">
                                <h3>Solicite um Contato Grátis</h3>
                                <p>
                                    Orçamento rápido, os melhores preços do mercado, técnicos qualificados,
                                    Garantia dos serviços prestados e rapidez.
                                    Faça um orçamento sem compromisso e veja as vantagens de realizar seus serviços conosco.
                                </p>
                            </div>{/* /.title */}
                        </div>{/* /.col-md-6 */}
                        <div className="col-md-6">
                            <form ref={form} onSubmit={sendEmailForm} className="contact-form row">
                                <div className="col-md-6">
                                    <p>Nome Completo</p>
                                    <input type="text" placeholder="Seu nome"
                                        name="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-6">
                                    <p>Email</p>
                                    <input type="email" placeholder="Seu e-mail"
                                        name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-6">
                                    <p>No. Telefone</p>
                                    <input type="text" placeholder="(ddd) 12345-6789"
                                        name="telefone" value={telefone} onChange={(e) => { setTelefone(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-6">
                                    <p>Qual o produto?</p>
                                    <select className="bootstrap-select dropup form-select" aria-label="Default select example" name="message"
                                        value={message} onChange={(e) => { setMessage(e.target.value) }}>
                                        <option selected>-- Selecione um Produto --</option>
                                        <option value="gostaria de contato sobre: barras redondas">Barras Redondas</option>
                                        <option value="gostaria de contato sobre: bobinas">Bobinas</option>
                                        <option value="gostaria de contato sobre: cantoneiras">Cantoneiras</option>
                                        <option value="gostaria de contato sobre: chapas">Chapas</option>
                                        <option value="gostaria de contato sobre: tubos quadrados">Tubos Quadrados</option>
                                        <option value="gostaria de contato sobre: tubos retangulares">Tubos Retangulares</option>
                                        <option value="gostaria de contato sobre: tubos schedule">Tubos Schedule</option>
                                        <option value="gostaria de contato sobre: tubos od tubing">Tubos OD / Tubing</option>
                                    </select>
                                </div>{/* /.col-md-6 */}

                                <input type="hidden" name="assunto" value="Orçamento Grátis Home" />
                                <div className="col-md-12">
                                    <button type="submit">Solicitar Contato</button>
                                </div>{/* /.col-md-12 */}
                            </form>
                            <div className="result" />{/* /.result */}
                        </div>{/* /.col-md-6 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </div>{/* /.inner */}
        </section>

    );
}