export function TubosQuadrados() {
    return (

        <section className="blog-page sec-pad">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="single-blog-page-content">
                            <h3>Especificações Técnicas</h3>
                            <div className="meta-info">
                                <a href="#"><i className="fas fa-check" />
                                    <b>Ligas disponíveis:</b> 304, 304L, 316, 316L, 321, Série 200, duplex e super duplex
                                </a>
                            </div>{/* /.meta-info */}
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Bitola</th>
                                        <th scope="col">1</th>
                                        <th scope="col">1.2</th>
                                        <th scope="col">1.5</th>
                                        <th scope="col">2</th>
                                        <th scope="col">2.5</th>
                                        <th scope="col">3</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    <tr>
                                        <td>10 x 10</td><td>0.28</td><td>0.33</td><td>0.40</td><td>0.51</td><td>0.6</td><td>0.67</td>
                                    </tr>
                                    <tr>
                                        <td>12 x 12</td><td>0.35</td><td>0.41</td><td>0.50</td><td>0.64</td><td>0.76</td><td>0.86</td>
                                    </tr>
                                    <tr>
                                        <td>15 x 15</td><td>0.44</td><td>0.52</td><td>0.64</td><td>0.83</td><td>1</td><td>1.15</td>
                                    </tr>
                                    <tr>
                                        <td>20 x 20</td><td>0.60</td><td>0.72</td><td>0.88</td><td>1.15</td><td>1.4</td><td>1.63</td>
                                    </tr>
                                    <tr>
                                        <td>25 x 25</td><td>0.76</td><td>0.91</td><td>1.12</td><td>1.47</td><td>1.8</td><td>2.11</td>
                                    </tr>
                                    <tr>
                                        <td>30 x 30</td><td>0</td><td>1.10</td><td>1.36</td><td>1.79</td><td>2.2</td><td>2.59</td>
                                    </tr>
                                    <tr>
                                        <td>35 x 35</td><td>1.08</td><td>1.29</td><td>1.60</td><td>2.11</td><td>2.6</td><td>3.07</td>
                                    </tr>
                                    <tr>
                                        <td>38 x 38</td><td>1.18</td><td>1.41</td><td>1.75</td><td>2.30</td><td>2.84</td><td>3.36</td>
                                    </tr>
                                    <tr>
                                        <td>40 x 40</td><td>1.24</td><td>1.48</td><td>1.84</td><td>2.43</td><td>3</td><td>3.55</td>
                                    </tr>
                                    <tr>
                                        <td>50 x 50</td><td>1.56</td><td>1.87</td><td>2.32</td><td>3.07</td><td>3.8</td><td>4.51</td>
                                    </tr>
                                    <tr>
                                        <td>60 x 60</td><td>1.88</td><td>2.25</td><td>2.80</td><td>3.71</td><td>4.6</td><td>5.47</td>
                                    </tr>
                                    <tr>
                                        <td>70 x 70</td><td>2.20</td><td>2.64</td><td>3.28</td><td>4.35</td><td>5.4</td><td>6.43</td>
                                    </tr>
                                    <tr>
                                        <td>80 x 80</td><td>2.52</td><td>3.02</td><td>3.76</td><td>4.99</td><td>6.2</td><td>7.39</td>
                                    </tr>
                                    <tr>
                                        <td>100 x 100</td><td>3.16</td><td>3.79</td><td>4.72</td><td>6.27</td><td>7.8</td><td>9.31</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>{/* /.single-blog-page-content */}
                    </div>{/* /.col-md-8 */}
                </div>{/* /.row */}
            </div>{/* /.container */}
        </section>

    );
}