export function BarrasRedondas() {
    return (

        <section className="blog-page sec-pad">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="single-blog-page-content">
                            <h3>Especificações Técnicas</h3>
                            <div className="meta-info">
                                <a href="#"><i className="fas fa-check" />
                                    <b>Ligas disponíveis:</b> 304, 304L, 316, 316L, 410, 420 e Serie 200
                                </a>
                            </div>{/* /.meta-info */}
                            <table className="table table-striped">
                            </table>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Dimensão em Pol.
                                        </th>
                                        <th scope="col">
                                            Dimensão em mm
                                        </th>
                                        <th scope="col">
                                            Peso por Metro
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    <tr>
                                        <td>1/8"</td><td>3.17</td><td>0.06</td>
                                    </tr>
                                    <tr>
                                        <td>5/32"</td><td>3.97</td><td>0.09</td>
                                    </tr>
                                    <tr>
                                        <td>3/16"</td><td>4.76</td><td>0.14</td>
                                    </tr>
                                    <tr>
                                        <td>7/32"</td><td>5.55</td><td>0.19</td>
                                    </tr>
                                    <tr>
                                        <td>1/4"</td><td>6.35</td><td>0.25</td>
                                    </tr>
                                    <tr>
                                        <td>9/32"</td><td>7.14</td><td>0.32</td>
                                    </tr>
                                    <tr>
                                        <td>5/16"</td><td>7.93</td><td>0.39</td>
                                    </tr>
                                    <tr>
                                        <td>3/8"</td><td>9.53</td><td>0.57</td>
                                    </tr>
                                    <tr>
                                        <td>7/16"</td><td>11.11</td><td>0.77</td>
                                    </tr>
                                    <tr>
                                        <td>1/2"</td><td>12.7</td><td>1.01</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>{/* /.single-blog-page-content */}
                    </div>{/* /.col-md-8 */}
                </div>{/* /.row */}
            </div>{/* /.container */}
        </section>

    );
}