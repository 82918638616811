import { Link } from "react-router-dom";
import slide1 from "../assets/slide/slide1.webp";
import slide2 from "../assets/slide/slide2.webp";

export function Carousel() {
    return (
        <div id="minimal-bootstrap-carousel" className="carousel slide carousel-fade slider-home-three" data-ride="carousel">
            {/* Wrapper for slides */}
            <div className="carousel-inner" role="listbox">
                <div className="item active slide-1" style={{ backgroundImage: `url(${slide1})`, backgroundPosition: 'left center' }}>
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="box valign-middle">
                                <div className="content text-left">
                                    <h2 data-animation="animated fadeInUp">Produtos</h2>
                                    <p data-animation="animated fadeInDown">Linha completa de tubos, tubing, barras, chapas, bobinas e cantoneiras</p>
                                    <a href="#products" data-target="html" className="banner-btn" data-animation="animated fadeInDown">Sobre</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item slide-2" style={{ backgroundImage: `url(${slide2})`, backgroundPosition: 'center center' }}>
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="box valign-middle">
                                <div className="content text-left">
                                    <h2 data-animation="animated fadeInUp">Qualidade</h2>
                                    <p data-animation="animated fadeInDown">Firmamos um compromisso de oferecer produtos de qualidade aos nossos clientes</p>
                                    <Link to="about-us" className="banner-btn" data-animation="animated fadeInDown">Sobre</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Controls */}
            <a className="left carousel-control" href="#minimal-bootstrap-carousel" role="button" data-slide="prev">
                <i className="fas fa-angle-left" />
                <span className="sr-only">Previous</span>
            </a>
            <a className="right carousel-control" href="#minimal-bootstrap-carousel" role="button" data-slide="next">
                <i className="fas fa-angle-right" />
                <span className="sr-only">Next</span>
            </a>
            <ul className="carousel-indicators list-inline custom-navigation">
                <li data-target="#minimal-bootstrap-carousel" data-slide-to={0} className="active" />
                <li data-target="#minimal-bootstrap-carousel" data-slide-to={1} />
            </ul>
        </div>

    );
}