import emailjs from '@emailjs/browser';
import { FormEvent, useRef, useState } from 'react';
import ReactGA from 'react-ga';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function Price() {

    const MySwal = withReactContent(Swal);

    const [name, setName] = useState("");
    const [empresa, setEmpresa] = useState("");
    const [CPForCNPJ, setCPForCNPJ] = useState("");
    const [telefone, setTelefone] = useState("");
    const [email, setEmail] = useState("");
    const [canal, setCanal] = useState("");
    const [endereco, setEndereco] = useState("");
    const [message, setMessage] = useState("");

    const form = useRef<any>();

    const sendEmailForm = (e: FormEvent) => {
        e.preventDefault();

        ReactGA.event({
            category: 'event',
            action: 'conversion',
            label: '/contact',
        })

        emailjs.sendForm('service_ou8h4xs', 'template_d1y2d6d', form.current, '62uANeVoJgE5dnHM3')
            .then(() => {

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Enviado com sucesso!'
                })

                setName("");
                setEmpresa("");
                setCPForCNPJ("");
                setTelefone("");
                setEmail("");
                setCanal("");
                setEndereco("");
                setMessage("");

            }).catch((err) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'error',
                    title: 'Tente novamente!'
                })
            });
    };


    return (
        <div>
            <section className="contact-page-content sec-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <form ref={form} onSubmit={sendEmailForm} className="contact-form row">
                                <div className="col-md-4">
                                    <p>Nome:</p>
                                    <input type="text"
                                        name="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-4">
                                    <p>Nome da Empresa:</p>
                                    <input type="text"
                                        name="empresa" value={empresa} onChange={(e) => { setEmpresa(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-4">
                                    <p>CNPJ ou CPF</p>
                                    <input type="text"
                                        name="CPForCNPJ" value={CPForCNPJ} onChange={(e) => { setCPForCNPJ(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-4">
                                    <p>Telefone</p>
                                    <input type="text" name="telefone"
                                        value={telefone} onChange={(e) => { setTelefone(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-4">
                                    <p>E-mail</p>
                                    <input type="email" name="email"
                                        value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-4">
                                    <p>Como você chegou até nós?:</p>
                                    <select className="bootstrap-select dropup form-select" aria-label="Default select example" name="canal"
                                        value={canal} onChange={(e) => { setCanal(e.target.value) }}>
                                        <option>-- Selecione um Produto --</option>
                                        <option value="Pesquisa Google">Pesquisa Google</option>
                                        <option value="Redes Sociais">Redes Sociais</option>
                                        <option value="Indicação">Indicação</option>
                                        <option value="Outros">Outros</option>
                                    </select>
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-12">
                                    <p>Endereço:</p>
                                    <input type="text" name="endereco"
                                        value={endereco} onChange={(e) => { setEndereco(e.target.value) }} />
                                </div>{/* /.col-md-6 */}
                                <div className="col-md-12">
                                    <p>Informe quais serviços deseja e alguma informação que seja necessário sabermos:</p>
                                    <textarea name="message" rows={5} defaultValue={""}
                                        value={message} onChange={(e) => { setMessage(e.target.value) }} />
                                    <button type="submit">Enviar</button>
                                </div>{/* /.col-md-6 */}
                            </form>{/* /.contact-form */}
                            <div className="result" />{/* /.result */}
                        </div>{/* /.col-md-8 */}

                    </div>{/* /.row */}
                </div>{/* /.container */}
            </section>{/* /.contact-page-content */}

        </div>
    );
}