import React from 'react';
import ReactGA from 'react-ga';

import { Route, Routes } from 'react-router-dom';
import { AboutCompany } from './components/AboutCompany';
import { Bobinas } from './components/Bobinas';
import { Breadcrumbs } from './components/Breadcrumbs';
import { Cantoneiras } from './components/Cantoneiras';
import { Carousel } from './components/Carousel';
import { Chapas } from './components/Chapas';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { TuboSchedule } from './components/TuboSchedule';
import { TubosOdTubing } from './components/TubosOdTubing';
import { MoreInfo } from './components/MoreInfo';
import { BarrasRedondas } from './components/BarrasRedondas';
import { TubosQuadrados } from './components/TubosQuadrados';
import { Products } from './components/Products';
import { RequestQuote } from './components/RequestQuote';

import aboutus from "./assets/banner/aboutus.webp";
import bobinas from "./assets/banner/bobinas.webp";
import cantoneiras from "./assets/banner/cantoneiras.webp";
import chapas from "./assets/banner/chapas.webp";
import tubosod from "./assets/banner/tubosod.webp";
import tubosschedule from "./assets/banner/tubosschedule.webp";
import barrasredondas from "./assets/banner/barrasredondas.webp";
import tubosquadrados from "./assets/banner/tubosquadrados.webp";
import tubosretangulares from "./assets/banner/tubosretangulares.webp";
import { Price } from './components/Price';
import { SecurityPolicy } from './components/SecurtiyPolicy';

const trackingId = "AW-10863409515/Dpw4CMeE3owYEOvyibwo"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

function App() {
  return (

    <Routes>

      <Route path="/" element={
        <div>
          <Header />
          <Carousel />
          <br />
          <Products namePage="home" />
          <RequestQuote />
          <br />
          <AboutCompany />
          <Footer />
        </div>
      } />

      <Route path="/privacy-policy" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Privacy Policy and Terms of Use" imagePage={aboutus} />
          <SecurityPolicy />
          <Footer />
        </div>
      } />

      <Route path="/about-us" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Sobre Nós" imagePage={aboutus} />
          <AboutCompany />
          <br />
          <MoreInfo />
          <Footer />
        </div>
      } />

      <Route path="/contact" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Contato" imagePage={aboutus} />
          <Contact />
          <Footer />
        </div>
      } />

      <Route path="/contact-price" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Faça Sua Cotação" imagePage={aboutus} />
          <Price />
          <Footer />
        </div>
      } />

      <Route path="/product/tubos-schedule" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Tubos Schedule" imagePage={tubosschedule} />
          <TuboSchedule />
          <Products namePage="" />
          <MoreInfo />
          <Footer />
        </div>
      } />

      <Route path="/product/tubos-od-tubing" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Tubos OD / Tubing" imagePage={tubosod} />
          <TubosOdTubing />
          <Products namePage="" />
          <MoreInfo />
          <Footer />
        </div>
      } />

      <Route path="/product/chapas" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Chapas" imagePage={chapas} />
          <Chapas />
          <Products namePage="" />
          <MoreInfo />
          <Footer />
        </div>
      } />

      <Route path="/product/bobinas" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Bobinas" imagePage={bobinas} />
          <Bobinas />
          <Products namePage="" />
          <MoreInfo />
          <Footer />
        </div>
      } />

      <Route path="/product/cantoneiras" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Cantoneiras" imagePage={cantoneiras} />
          <Cantoneiras />
          <Products namePage="" />
          <MoreInfo />
          <Footer />
        </div>
      } />

      <Route path="/product/barras-redondas" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Barras Redondas" imagePage={barrasredondas} />
          <BarrasRedondas />
          <Products namePage="" />
          <MoreInfo />
          <Footer />
        </div>
      } />

      <Route path="/product/tubos-quadrados" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Tubos Quadrados" imagePage={tubosquadrados} />
          <TubosQuadrados />
          <Products namePage="" />
          <MoreInfo />
          <Footer />
        </div>
      } />

      <Route path="/product/tubos-retangulares" element={
        <div>
          <Header />
          <Breadcrumbs namePage="Tubos Retangulares" imagePage={tubosretangulares} />
          <TubosQuadrados />
          <Products namePage="" />
          <MoreInfo />
          <Footer />
        </div>
      } />

      {/* Use it in this way, and it should work: */}
      <Route path='*' element={
        <div>
          <Header />
          <Carousel />
          <br />
          <Products namePage="home" />
          <RequestQuote />
          <br />
          <AboutCompany />
          <Footer />
        </div>
      } />

    </Routes>

  );
}

export default App;
