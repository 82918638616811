export function TuboSchedule() {
    return (

        <section className="blog-page sec-pad">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="single-blog-page-content">
                            <h3>Especificações Técnicas</h3>
                            <div className="meta-info">
                                <a href="#"><i className="fas fa-check" />
                                    <b>Ligas disponíveis:</b> AISI 304, 304L, 316, 316L e outras opções sob consulta
                                </a>
                                <br />
                                <a href="#"><i className="fas fa-check" />
                                    <b>Acabamento:</b> Decapado, escovado e polido
                                </a>
                            </div>{/* /.meta-info */}
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Diâmetro
                                        </th>
                                        <th scope="col">
                                            SCH 05s
                                        </th>
                                        <th scope="col">
                                            SCH 10s
                                        </th>
                                        <th scope="col">
                                            SCH 40s
                                        </th>
                                        <th scope="col">
                                            SCH 80s
                                        </th>
                                        <th scope="col">
                                            SCH 160s
                                        </th>
                                        <th scope="col">
                                            XXS
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Pol.</th>
                                        <th scope="col">Externo</th>
                                        <th scope="col">Parede</th>
                                        <th scope="col">peso</th>
                                        <th scope="col">Parede</th>
                                        <th scope="col">peso</th>
                                        <th scope="col">Parede</th>
                                        <th scope="col">peso</th>
                                        <th scope="col">Parede</th>
                                        <th scope="col">peso</th>
                                        <th scope="col">Parede</th>
                                        <th scope="col">peso</th>
                                        <th scope="col">Parede</th>
                                        <th scope="col">peso</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    <tr>
                                        <td >1/8"</td><td >10.29</td><td >-</td><td >-</td><td>1.24</td><td>0.28</td><td >1.73</td><td >0.37</td><td >2.41</td><td >0.47</td><td >-</td><td >-</td><td >-</td><td >-</td>
                                    </tr>
                                    <tr>
                                        <td >1/4"</td><td >13.72</td><td >-</td><td >-</td><td>1.65</td><td>0.50</td><td >2.24</td><td >0.64</td><td >3.02</td><td >0.81</td><td >3.3</td><td >0.86</td><td >-</td><td >-</td>
                                    </tr>
                                    <tr>
                                        <td >3/8"</td><td >17.15</td><td >-</td><td >-</td><td>1.65</td><td>0.64</td><td >2.31</td><td >0.86</td><td >3.2</td><td >1.12</td><td >4.75</td><td >1.48</td><td >-</td><td >-</td>
                                    </tr>
                                    <tr>
                                        <td >1/2"</td><td >21.34</td><td >1.65</td><td >0.81</td><td>2.11</td><td>1.01</td><td >2.77</td><td >1.29</td><td >3.73</td><td >1.65</td><td >4.78</td><td >1.98</td><td >7.47</td><td >2.6</td>
                                    </tr>
                                    <tr>
                                        <td >3/4"</td><td >26.67</td><td >1.65</td><td >1.03</td><td>2.11</td><td>1.30</td><td >2.87</td><td >1.71</td><td >3.91</td><td >2.23</td><td >5.56</td><td >2.94</td><td >7.82</td><td >3.70</td>
                                    </tr>
                                    <tr>
                                        <td >1"</td><td >33.4</td><td >1.65</td><td >1.31</td><td>2.77</td><td>2.13</td><td >3.38</td><td >2.55</td><td >4.55</td><td >3.29</td><td >6.36</td><td >4.32</td><td >9.09</td><td >5.55</td>
                                    </tr>
                                    <tr>
                                        <td >1.1/4"</td><td >42.16</td><td >1.65</td><td >1.67</td><td>2.77</td><td>2.74</td><td >3.56</td><td >3.45</td><td >4.85</td><td >4.54</td><td >6.35</td><td >57150</td><td >9.7</td><td >7.91</td>
                                    </tr>
                                    <tr>
                                        <td >1.1/2"</td><td >48.26</td><td >1.65</td><td >1.93</td><td>2.77</td><td>3.16</td><td >3.68</td><td >4.12</td><td >5.08</td><td >5.51</td><td >7.14</td><td >7.37</td><td >10.16</td><td >9.72</td>
                                    </tr>
                                    <tr>
                                        <td >2"</td><td >60.33</td><td >1.65</td><td >2.43</td><td>2.77</td><td>4.00</td><td >3.91</td><td >5.54</td><td >5.54</td><td >7.62</td><td >8.74</td><td >11.33</td><td >11.07</td><td >13.70</td>
                                    </tr>
                                    <tr>
                                        <td >2.1/2"</td><td >73.03</td><td >2.11</td><td >3.76</td><td>3.05</td><td>5.36</td><td >5.16</td><td >8.80</td><td >7.01</td><td >11.63</td><td >9.52</td><td >15.19</td><td >14.02</td><td >20.79</td>
                                    </tr>
                                    <tr>
                                        <td >3"</td><td >88.9</td><td >2.11</td><td >4.60</td><td>3.05</td><td>6.58</td><td >5.49</td><td >11.50</td><td >7.62</td><td >15.56</td><td >11.13</td><td >21.75</td><td >15.24</td><td >28.21</td>
                                    </tr>
                                    <tr>
                                        <td >3.1/2"</td><td >101.6</td><td >2.11</td><td >5.27</td><td>3.05</td><td>7.55</td><td >5.74</td><td >13.82</td><td >8.08</td><td >18.99</td><td >-</td><td >-</td><td >16.15</td><td >34.68</td>
                                    </tr>
                                    <tr>
                                        <td >4"</td><td >114.3</td><td >2.11</td><td >5.94</td><td>3.05</td><td>8.52</td><td >6.02</td><td >16.38</td><td >8.56</td><td >22.74</td><td >13.49</td><td >34.17</td><td >17.12</td><td >41.81</td>
                                    </tr>
                                    <tr>
                                        <td >5"</td><td >141.3</td><td >2.77</td><td >9.64</td><td>3.4</td><td>11.78</td><td >6.55</td><td >22.18</td><td >9.53</td><td >31.56</td><td >15.88</td><td >50.05</td><td >19.05</td><td >58.53</td>
                                    </tr>
                                    <tr>
                                        <td >6"</td><td >168.28</td><td >2.77</td><td >11.52</td><td>3.4</td><td>14.08</td><td >7.11</td><td >28.80</td><td >10.97</td><td >43.37</td><td >18.26</td><td >68.84</td><td >21.95</td><td >80.72</td>
                                    </tr>
                                    <tr>
                                        <td >8"</td><td >219.08</td><td >2.77</td><td >15.05</td><td>3.76</td><td>20.34</td><td >8.18</td><td >43.35</td><td >12.7</td><td >65.87</td><td >23.01</td><td >113.38</td><td >22.23</td><td >109.98</td>
                                    </tr>
                                    <tr>
                                        <td >10"</td><td >273.05</td><td >3,40</td><td >23,04</td><td>4.19</td><td>28.31</td><td >9.27</td><td >61.45</td><td >12.7</td><td >83.10</td><td >28.57</td><td >175.54</td><td >25.4</td><td >158.09</td>
                                    </tr>
                                    <tr>
                                        <td >12"</td><td >323.85</td><td >4,00</td><td >32,15</td><td>4.57</td><td>36.67</td><td >9.53</td><td >75.28</td><td >12.7</td><td >99.31</td><td >33.32</td><td >243.29</td><td >25.4</td><td >190.52</td>
                                    </tr>
                                    <tr>
                                        <td >14"</td><td >355.6</td><td >-3,96</td><td >34,99</td><td>4.78</td><td>42.14</td><td >11,13</td><td >96,35</td><td >19,05</td><td >161,13</td><td >35,74</td><td >287,09</td><td >-</td><td >-</td>
                                    </tr>
                                    <tr>
                                        <td >16"</td><td >406.4</td><td >4,20</td><td >42,45</td><td>4.78</td><td>48.24</td><td >12,70</td><td >125,66</td><td >21,44</td><td >207,43</td><td >40,49</td><td >372,35</td><td >-</td><td >-</td>
                                    </tr>
                                    <tr>
                                        <td >18"</td><td >457.2</td><td >-</td><td >-</td><td>4.75</td><td>54.01</td><td >14,27</td><td >158,85</td><td >23,83</td><td >259,55</td><td >45,25</td><td >468,49</td><td >-</td><td >-</td>
                                    </tr>
                                    <tr>
                                        <td >20”</td><td >508</td><td >-</td><td >-</td><td>5.54</td><td>69.96</td><td >15,09</td><td >186,93</td><td >26,19</td><td >317,14</td><td >50,01</td><td >575,64</td><td >-</td><td >-</td>
                                    </tr>
                                    <tr>
                                        <td >22”</td><td >558.8</td><td >-</td><td >-</td><td>5.54</td><td>77.03</td><td >15,87</td><td >216,61</td><td >28,57</td><td >380,79</td><td >53,97</td><td >684,76</td><td >-</td><td >-</td>
                                    </tr>
                                    <tr>
                                        <td >24”</td><td >609.6</td><td >-</td><td >-</td><td>6.35</td><td>96.27</td><td >17,47</td><td >260,05</td><td >30,96</td><td >450,27</td><td >-</td><td >-</td><td >-</td><td >-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>{/* /.single-blog-page-content */}
                    </div>{/* /.col-md-8 */}
                </div>{/* /.row */}
            </div>{/* /.container */}
        </section>

    );
}