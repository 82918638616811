export function TubosOdTubing() {
    return (

        <section className="blog-page sec-pad">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="single-blog-page-content">
                            <h3>Especificações Técnicas</h3>
                            <div className="meta-info">
                                <a href="#"><i className="fas fa-check" />
                                    <b>Ligas disponíveis:</b> 304, 304L, 316, 316L, 321, Série 200, duplex e super duplex
                                </a>
                            </div>{/* /.meta-info */}
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Diâmetro Externo
                                        </th>
                                        <th scope="col">
                                            Espessuras da parede (mm) e Peso por Metro (kg/m)
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Polegada</th>
                                        <th scope="col">mm</th>
                                        <th scope="col">0.50</th>
                                        <th scope="col">0.70</th>
                                        <th scope="col">0.89</th>
                                        <th scope="col">1.00</th>
                                        <th scope="col">1.20</th>
                                        <th scope="col">1.24</th>
                                        <th scope="col">1.50</th>
                                        <th scope="col">1.65</th>
                                        <th scope="col">2.00</th>
                                        <th scope="col">2.11</th>
                                        <th scope="col">2.50</th>
                                        <th scope="col">3.00</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    <tr>
                                        <td>-</td><td>6</td><td>0.06</td><td>0.09</td><td>0.11</td><td>0.12</td><td>0.14</td><td>0.14</td><td>0.16</td><td>0.18</td><td>0.20</td><td>0.18</td><td>0.21</td><td>0.22</td>
                                    </tr>
                                    <tr>
                                        <td>1/4"</td><td>	6.35</td><td>	0.07</td><td>	0.09</td><td>	0.12</td><td>	0.13</td><td>	0.15</td><td>	0.15</td><td>	0.18</td><td>	0.19</td><td>	0.21</td><td>	0.19</td><td>	0.24</td><td>	0.25</td>
                                    </tr>
                                    <tr>
                                        <td>-</td><td> 7.93</td><td>	0.09</td><td>	0.12</td><td>	0.15</td><td>	0.17</td><td>	0.20</td><td>	0.20</td><td>	0.24</td><td>	0.26</td><td>	0.29</td><td>	0.26</td><td>	0.34</td><td>	0.37</td>
                                    </tr>
                                    <tr>
                                        <td>-</td><td> 8</td><td>	0.09</td><td>	0.12</td><td>	0.15</td><td>	0.17</td><td>	0.20</td><td>	0.21</td><td>	0.24</td><td>	0.26</td><td>	0.30</td><td>	0.26</td><td>	0.34</td><td>	0.37</td>
                                    </tr>
                                    <tr>
                                        <td>3/8"</td><td>	9.53</td><td>	0.11</td><td>	0.15</td><td>	0.19</td><td>	0.21</td><td>	0.25</td><td>	0.25</td><td>	0.30</td><td>	0.32</td><td>	0.37</td><td>	0.32</td><td>	0.44	</td><td>0.49</td>
                                    </tr>
                                    <tr>
                                        <td>-</td><td> 10</td><td>	0.11</td><td>	0.16</td><td>	0.20</td><td>	0.22	</td><td>0.26</td><td>	0.27</td><td>	0.32</td><td>	0.34</td><td>	0.40</td><td>	0.34</td><td>	0.47</td><td>	0.52</td>
                                    </tr>
                                    <tr>
                                        <td>-</td><td> 12</td><td>	0.14</td><td>	0.19</td><td>	0.24</td><td>	0.27</td><td>	0.32</td><td>	0.33</td><td>	0.39</td><td>	0.42</td><td>	0.50</td><td>	0.42</td><td>	0.59</td><td>	0.67</td>
                                    </tr>
                                    <tr>
                                        <td>1/2"</td><td>	12.7</td><td>	0.15</td><td>	0.21</td><td>	0.26</td><td>	0.29	</td><td>0.34</td><td>	0.35</td><td>	0.42</td><td>	0.45</td><td>	0.53</td><td>	0.45</td><td>	0.64</td><td>	0.73</td>
                                    </tr>
                                    <tr>
                                        <td>-</td><td> 15</td><td>	0.18</td><td>	0.25</td><td>	0.31</td><td>	0.35</td><td>	0.41</td><td>	0.42	</td><td>0.50	</td><td>0.55</td><td>	0.65	</td><td>0.55</td><td>	0.78</td><td>	0.90</td>
                                    </tr>
                                    <tr>
                                        <td> 5/8"</td><td>	15.87</td><td>	0.19</td><td>	0.26	</td><td>0.33	</td><td>0.37</td><td>	0.44	</td><td>0.45</td><td>	0.54	</td><td>0.58</td><td>	0.69</td><td>	0.58</td><td>	0.84</td><td>	0.97</td>
                                    </tr>
                                    <tr>
                                        <td> -</td><td> 16</td><td>	0.19	</td><td>0.26</td><td>	0.33</td><td>	0.37</td><td>	0.44</td><td>	0.46	</td><td>0.54</td><td>	0.59</td><td>	0.70</td><td>	0.59</td><td>	0.84</td><td>	0.98</td>
                                    </tr>
                                    <tr>
                                        <td>-</td><td> 18</td><td>	0.21</td><td>	0.30</td><td>	0.38</td><td>	0.42</td><td>	0.50</td><td>	0.52</td><td>	0.62</td><td>	0.67</td><td>	0.80</td><td>	0.67</td><td>	0.97</td><td>	1.13</td>
                                    </tr>
                                    <tr>
                                        <td>3/4"</td><td>	19.05</td><td>	0.23</td><td>	0.32</td><td>	0.40</td><td>	0.45	</td><td>0.53</td><td>	0.55</td><td>	0.66</td><td>	0.72</td><td>	0.85</td><td>	0.72</td><td>	1.04</td><td>	1.21</td>
                                    </tr>
                                    <tr>
                                        <td>-</td><td> 20</td><td>	0.24</td><td>	0.33</td><td>	0.42</td><td>	0.47	</td><td>0.56</td><td>	0.58</td><td>	0.69</td><td>	0.76</td><td>	0.90</td><td>	0.76</td><td>	1.09</td><td>	1.28</td>
                                    </tr>
                                    <tr>
                                        <td>7/8"</td><td>22.22</td><td>0.27</td><td>0.37</td><td>0.47</td><td>0.53</td><td>0.63</td><td>0.65</td><td>0.78</td><td>0.85</td><td>1.01</td><td>0.85</td><td>1.23</td><td>1.44</td>
                                    </tr>
                                    <tr>
                                        <td>-</td><td>25</td><td>0.30</td><td>0.42</td><td>0.53</td><td>0.60</td><td>0.71</td><td>0.74</td><td>0.88</td><td>0.96</td><td>1.15</td><td>0.96</td><td>1.41</td><td>1.65</td></tr>
                                    <tr>
                                        <td>1"	</td><td>25.4</td><td>0.31</td><td>0.43</td><td>0.54</td><td>0.61</td><td>0.73</td><td>0.75</td><td>0.90</td><td>0.98</td><td>1.17</td><td>0.98</td><td>1.43</td><td>1.68</td></tr>
                                    <tr>

                                        <td>- </td>
                                        <td>28	</td>
                                        <td>0.34</td>
                                        <td>0.48</td>
                                        <td>0.60</td>
                                        <td>0.67</td>
                                        <td>0.80</td>
                                        <td>0.83</td>
                                        <td>0.99</td>
                                        <td>1.09</td>
                                        <td>1.30</td>
                                        <td>1.09</td>
                                        <td>1.60</td>
                                        <td>1.88</td>
                                    </tr>
                                    <tr>

                                        <td>1.1/8"	</td>
                                        <td>28.6	</td>
                                        <td>0.35	</td>
                                        <td>0.49	</td>
                                        <td>0.62	</td>
                                        <td>0.69	</td>
                                        <td>0.82	</td>
                                        <td>0.85	</td>
                                        <td>1.02	</td>
                                        <td>1.11	</td>
                                        <td>1.33	</td>
                                        <td>1.11	</td>
                                        <td>1.64	</td>
                                        <td>1.93</td>
                                    </tr>
                                    <tr>

                                        <td>1.1/4"</td>
                                        <td>31.75</td>
                                        <td>-</td>
                                        <td>0.54</td>
                                        <td>0.69</td>
                                        <td>0.77</td>
                                        <td>0.92</td>
                                        <td>0.95</td>
                                        <td>1.14</td>
                                        <td>1.24</td>
                                        <td>1.49</td>
                                        <td>1.24</td>
                                        <td>1.83</td>
                                        <td>2.16</td>
                                    </tr>
                                    <tr>

                                        <td>-</td>
                                        <td>32</td>
                                        <td>-</td>
                                        <td>0.55</td>
                                        <td>0.69</td>
                                        <td>0.77</td>
                                        <td>0.92</td>
                                        <td>0.95</td>
                                        <td>1.15</td>
                                        <td>1.25</td>
                                        <td>1.50</td>
                                        <td>1.25</td>
                                        <td>1.85</td>
                                        <td>2.18</td>
                                    </tr>
                                    <tr>

                                        <td> -</td>
                                        <td> 38</td>
                                        <td> -</td>
                                        <td> 0.65</td>
                                        <td> 0.83</td>
                                        <td> 0.93</td>
                                        <td> 1.11</td>
                                        <td> 1.14</td>
                                        <td> 1.37</td>
                                        <td> 1.50</td>
                                        <td> 1.81</td>
                                        <td> 1.50</td>
                                        <td> 2.23</td>
                                        <td> 2.63</td>
                                    </tr>
                                    <tr>

                                        <td>1.1/2"</td>
                                        <td>38.1</td>
                                        <td>-</td>
                                        <td>0.65</td>
                                        <td>0.83</td>
                                        <td>0.93</td>
                                        <td>1.11</td>
                                        <td>1.14</td>
                                        <td>1.38</td>
                                        <td>1.51</td>
                                        <td>1.81</td>
                                        <td>1.51</td>
                                        <td>2.23</td>
                                        <td>2.64</td>
                                    </tr>
                                    <tr>

                                        <td> -</td>
                                        <td> 42</td>
                                        <td> -</td>
                                        <td> 0.72</td>
                                        <td> 0.91</td>
                                        <td> 1.03</td>
                                        <td> 1.23</td>
                                        <td> 1.27</td>
                                        <td> 1.52</td>
                                        <td> 1.67</td>
                                        <td> 2.01</td>
                                        <td> 1.67</td>
                                        <td> 2.48</td>
                                        <td> 2.94</td>
                                    </tr>
                                    <tr>

                                        <td>1.3/4</td>
                                        <td>44.45</td>
                                        <td>-</td>
                                        <td>0.76</td>
                                        <td>0.97</td>
                                        <td>1.092</td>
                                        <td>1.30</td>
                                        <td>1.34</td>
                                        <td>1.61</td>
                                        <td>1.77</td>
                                        <td>2.13</td>
                                        <td>1.77</td>
                                        <td>2.63</td>
                                        <td>3.12</td>
                                    </tr>
                                    <tr>

                                        <td>2"</td>
                                        <td>50.8</td>
                                        <td>-</td>
                                        <td>0.88</td>
                                        <td>1.11</td>
                                        <td>1.25</td>
                                        <td>1.49</td>
                                        <td>1.54</td>
                                        <td>1.85</td>
                                        <td>2.03</td>
                                        <td>2.45</td>
                                        <td>2.03</td>
                                        <td>3.035</td>
                                        <td>3.60</td>
                                    </tr>
                                    <tr>
                                        <td>2.1/2"</td>
                                        <td>63.5</td>
                                        <td>-</td>
                                        <td>1.10</td>
                                        <td>1.40</td>
                                        <td>1.57</td>
                                        <td>1.87</td>
                                        <td>1.94</td>
                                        <td>2.33</td>
                                        <td>2.56</td>
                                        <td>3.09</td>
                                        <td>2.56</td>
                                        <td>3.83</td>
                                        <td>4.56</td>
                                    </tr>
                                    <tr>

                                        <td>3"</td>
                                        <td>76.2</td>
                                        <td>-</td>
                                        <td>1.32</td>
                                        <td>1.68</td>
                                        <td>1.89</td>
                                        <td>2.26</td>
                                        <td>2.33</td>
                                        <td>2.81</td>
                                        <td>3.09</td>
                                        <td>3.73</td>
                                        <td>3.09</td>
                                        <td>4.63</td>
                                        <td>5.52</td>
                                    </tr>
                                    <tr>
                                        <td>4"</td>
                                        <td>101.6</td>
                                        <td>-</td>
                                        <td>1.77</td>
                                        <td>2.25</td>
                                        <td>2.52</td>
                                        <td>3.02</td>
                                        <td>3.12</td>
                                        <td>3.77</td>
                                        <td>4.14</td>
                                        <td>5.00</td>
                                        <td>4.14</td>
                                        <td>6.22</td>
                                        <td>7.43</td>
                                    </tr>
                                    <tr>
                                        <td>5"</td>
                                        <td>127</td>
                                        <td>-</td>
                                        <td>2.22</td>
                                        <td>2.82</td>
                                        <td>3.16</td>
                                        <td>3.79</td>
                                        <td>3.92</td>
                                        <td>4.73</td>
                                        <td>5.19</td>
                                        <td>6.28</td>
                                        <td>5.19</td>
                                        <td>7.82</td>
                                        <td>9.35</td>
                                    </tr>
                                    <tr>
                                        <td>6"</td>
                                        <td>152.4</td>
                                        <td>-</td>
                                        <td>2.66</td>
                                        <td>3.38</td>
                                        <td>3.80</td>
                                        <td>4.56</td>
                                        <td>4.71</td>
                                        <td>5.69</td>
                                        <td>6.25</td>
                                        <td>7.56</td>
                                        <td>6.25</td>
                                        <td>9.42</td>
                                        <td>11.26</td>
                                    </tr>
                                    <tr>
                                        <td>8"</td>
                                        <td>203.2</td>
                                        <td>-</td>
                                        <td>3.56</td>
                                        <td>4.52</td>
                                        <td>5.08</td>
                                        <td>6.09</td>
                                        <td>6.29</td>
                                        <td>7.60</td>
                                        <td>8.36</td>
                                        <td>10.11</td>
                                        <td>8.36</td>
                                        <td>12.61</td>
                                        <td>15.09</td>
                                    </tr>
                                    <tr>
                                        <td>10"</td>
                                        <td>254</td>
                                        <td>-</td>
                                        <td>4.45</td>
                                        <td>5.66</td>
                                        <td>6.3</td>
                                        <td>7.62</td>
                                        <td>7.87</td>
                                        <td>9.52</td>
                                        <td>10.46</td>
                                        <td>12.67</td>
                                        <td>10.46</td>
                                        <td>15.80</td>
                                        <td>18.93</td>
                                    </tr>
                                    <tr>
                                        <td>12"</td>
                                        <td>304.8</td>
                                        <td>3.82</td>
                                        <td>5.35</td>
                                        <td>6.79</td>
                                        <td>7.63</td>
                                        <td>9.15</td>
                                        <td>9.46</td>
                                        <td>11.43</td>
                                        <td>12.57</td>
                                        <td>15.22</td>
                                        <td>12.57</td>
                                        <td>18.99</td>
                                        <td>22.76</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>{/* /.single-blog-page-content */}
                    </div>{/* /.col-md-8 */}
                </div>{/* /.row */}
            </div>{/* /.container */}
        </section>

    );
}